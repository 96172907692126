*
{
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: EastSeaDokdo;
    src: url(./fonts/East_Sea_Dokdo/EastSeaDokdo-Regular.ttf);
}

:root {
    --darkmartian: #330000;
    --darkmartian-trpt: #3300008f;
    --martian: #e4a28a;
    --martian-trpt: #e4a28aad;
    --lightmartian: #feaa8b;
    --properfontfam: EastSeaDokdo, Roboto, Helvetica, Arial, sans-serif;
    --properfontsize: 84px;
    --bodyfontfam: Helvetica, Arial, sans-serif;
    --bodyfontsize: 14px;
    --linkcolor: rgb(67 162 251);
}

.grabbable {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

 /* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}

html,
body
{
    overflow: hidden;
    background: black;
    font-family: var(--bodyfontfam);
    font-size: var(--bodyfontsize);
    font-weight: 100;
}

img{
    max-width: 90vw;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    border: 3px solid var(--darkmartian-trpt);
    transition: all 0.3s;
}

img:hover{
    border: 3px solid var(--martian);
    color: var(--lightmartian);
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.point
{
    position: absolute;
    top: 50%;
    left: 50%;
}
.point .label
{
    position: absolute;
    top: -16px;
    left: -16px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: var(--darkmartian-trpt);
    border: 1px solid var(--martian-trpt);
    color: var(--lightmartian);
    text-align: center;
    line-height: 32px;
    cursor: pointer;
    transform: scale(0.001, 0.001);
    transition: transform 0.4s;
}
.point.visible .label{
    transform: scale(1, 1);
}

.point:hover .text
{
    opacity: 1;
    transition: opacity 0.3s;
}

.point .text
{
    position: absolute;
    top: -44px;
    left: 13px;
    width: 120px;
    height: 28px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    color: #feaa8b;
    text-align: center;
    line-height: 1.3em;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
}


.properText
{
    font-family: var(--properfontfam);
    font-size: var(--properfontsize);
    font-weight: bold;
    color : white;
    font-weight: 100;
}

.properText.small
{
    font-size: 24pt;
    color : orange;
}

.entrytitle {
    position: absolute;
    top: 50vh;
    left: 50vw;
    height:0;
    width:0;
    pointer-events: none;
    /*opacity: 0;*/
    /*transition: opacity 2s, transform 2s;*/

    opacity:0;
    animation-duration: 3.5s;
    animation-name: entryanim;
}

.entrytitle > div{
    position: relative;
    top: -42px;
    left: -150px;
    width: 300px;
    height: 84px;
    margin: 0px 10px;
    text-align: center;
    line-height: 72px;
}

@keyframes entryanim {
    from { opacity: 0; }
    60% {  opacity: 1;}
    83% { opacity: 1; }
    to { opacity: 0; }
}

.navbar
{
    position: fixed;
    top: 0;
    left: 0;
    width: 90vw;
    height: 90vh;
    padding: 5vh 5vw;
    background: #49372ce0;
    /* background: #463931cf; */
    z-index: 1;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: white;
    touch-action: none;
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    overflow-x: hidden;
    overflow-y: auto;
    opacity: 0;

    transition: transform 0.3s;
    transform: translateX(100vw);
}

.navbar.visible
{   
    opacity:1;
    transform: translateX(0);
}

.navbar-item{
    padding: 2vw;
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    text-align: justify;

}

.navbar-item-image{
    aspect-ratio: 1;
    max-height : 612px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
}

.button-right-top{
    position: fixed;
    top:5vh;
    right:5vw;
}


.button-right-top.setting{
    /* background: gray; */
    transform: translate(36px,0) scaleX(0);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 128px;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
}

.button-right-top.setting.visible{
    opacity: 1;
    transform: translate(-27px,0) scaleX(1);
}

.button-right-top:hover{
    color: var(--linkcolor);
    cursor: pointer;
}


.button-bar{
    height: 40px;
    align-self: center;
    margin: 0 0 5px 0;
    width: 100%;
    display: flex;
}
.marbutton{
    flex-grow: 1;
    border-radius: 5pt;
    height: 40px;
    /* background: var(--darkmartian); */
    background: #fff2;
    border: 2px solid var(--darkmartian);
    color: var(--darkmartian);
    text-align: center;
    line-height: 37px;
    cursor: pointer;
    padding: 0 10px;
    display: flex;
    transition: all 0.3s;
}

.marbutton:hover{
    border: 2px solid var(--martian);
    color: var(--lightmartian);
    background: rgb(39 12 12 / 50%);
}
.marbutton__label{
    font-size: large;
}

.loading-bar{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #000000;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity 0.3s;
}

.loading-bar.hidden{
    opacity: 0;
}

.loading-bar__text{
    font-size: 2em;
    color: white;
    margin: 0 0 20px 0;
    font-family: serif;
}



@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    [class*="col-"] {
        width: 100%;
    }

    .navbar.visible{
        flex-direction: column;
    }
    
    .navbar-item{
        width: 90vw;
        height: 90vw;
        display: flex;
    }
}